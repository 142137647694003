<template>
    <div class="card bg-light-subtle">
        <div class="card-body gy-3 row">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    // ---
</script>
